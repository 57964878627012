import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { socialMediaIcons } from "../../../shared/icons";
import styles from "./Footer.module.scss";
import { isBrowser } from "../../../shared/helpers";
import useScript from "../../../hooks/useScripts";
import { config } from "../../../../config";

interface ILinkObject {
    path: string,
    title: string
}

const Footer = (props: PropsWithChildren<any>): ReactElement => {
    const query = useStaticQuery(
        graphql`
            query {
                allPrismicMainLayout {
                    edges {
                        node {
                            dataString
                        }
                    }
                } 
            }
        `
    );

    const data = JSON.parse(query.allPrismicMainLayout.edges[0].node.dataString);

    //navigation object
    const navigationLinks: Array<ILinkObject> = [
        {
            path: "/gym-members-policy",
            title: "Gym and Membership Policies"
        },
        {
            path: "/corporate-wellness",
            title: "Corporate Wellness"
        },
        {
            path: "/contact-us",
            title: "Contact Us"
        }
    ]

    return (
        <footer className={`${styles.footer} print-hidden`}>
            <div className={styles.firstSection}>
                <div className={styles.footerLogoWrapper}>
                    <Link to="/" className={styles.footerLogo}>
                        <img
                            src={data.footer_site_logo.url}
                            alt={data.footer_site_logo.alt}
                        />
                    </Link>
                </div>
                {
                    navigationLinks && navigationLinks.map((link: ILinkObject, i: number) => (
                        <Link to={link.path} className={styles.Link} key={i}>
                            <div className={styles.navItemCategory}>
                                {link.title}
                                <span>
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.7354 3.14709C10.8167 2.74092 10.5533 2.3458 10.1471 2.26457L3.52817 0.940782C3.122 0.859548 2.72688 1.12296 2.64564 1.52913C2.56441 1.9353 2.82782 2.33042 3.23399 2.41165L9.11748 3.58835L7.94078 9.47183C7.85954 9.878 8.12296 10.2731 8.52913 10.3544C8.9353 10.4356 9.33042 10.1722 9.41165 9.76601L10.7354 3.14709ZM1.41603 9.62404L10.416 3.62404L9.58397 2.37596L0.583975 8.37596L1.41603 9.62404Z" />
                                    </svg>
                                </span>
                            </div>
                        </Link>
                    ))
                }
            </div>
            <div className={styles.lastSection}>
                <div className={styles.leftControlGroup}>
                    <a href={'/terms-of-service'}>Terms of Service</a>
                    <a href={'/privacy-policy'}>Privacy Policy</a>
                    <a href={'/site-map'}>Site Map</a>
                </div>
                <div className={styles.rightControlGroup}>
                    <div className={styles.socialMediaWrapper}>
                        {data.footer_facebook_url && (
                            <a
                                target="_blank"
                                href={data.footer_facebook_url.url}
                                rel="noreferrer noopener"
                            >
                                {socialMediaIcons.facebook}
                            </a>
                        )}

                        {data.footer_instagram_url && (
                            <a
                                target="_blank"
                                href={data.footer_instagram_url.url}
                                rel="noreferrer noopener"
                            >
                                {socialMediaIcons.instagram}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;


