import { useState, useEffect } from "react";
import { isWindow } from "../shared/helpers";

const useHasWindow = () => {
  const [hasWindow, setHasWindow] = useState<boolean>(false);

  useEffect(() => {
    setHasWindow(isWindow);
  }, []);

  return hasWindow;
};

export default useHasWindow;