import React, { PropsWithChildren, useEffect, useState } from 'react'
import ChildrenDropDown from '../ChildrenDropdown'
import { chevron } from '../../../../shared/icons';
import { IMenuItem, INavItem } from '../Interfaces/MenuItemsInterfaces'
import styles from "./ItemWithChildrens.module.scss";
import { Link } from 'gatsby';


function NavItem(props: PropsWithChildren<INavItem>) {

    const { parent, childrens, handleItemSelection, isCurrent, item, handleClose } = props;

    const hasChildren = childrens.length > 0;

    const handleParentClose = (item : IMenuItem) => {
        handleItemSelection(item);
        if (handleClose) handleClose();
    }

    return (
        <>
            <li className={`${hasChildren ? styles.hasOptions : ""} print-hidden ${styles.navItem}`}>
                {
                    hasChildren ? (
                        <button
                            className={isCurrent ? styles.optionsVisible : ""}
                            onClick={() => handleItemSelection(item)}
                        >
                            {parent.name}
                            {<span>{chevron.down}</span>}
                        </button>
                    ) : (
                        <Link
                            key={parent.id}
                            to={parent.slug ? parent.slug : ""}
                            className={`${styles.menuItem} ${isCurrent ? styles.current : ""}`}
                            onClick={() => handleParentClose(item)}
                        >
                            {parent.name}
                        </Link>
                    )}
                {hasChildren && isCurrent && (
                    <ChildrenDropDown
                        items={childrens}
                        handleLinkClick={handleClose}
                    />
                )}
            </li>
        </>
    )
}

export default NavItem

