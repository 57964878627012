import { isBrowser } from "../shared/helpers";

class CookiesService {
    public static setCookie(key: string, value: string | null, options?:any) {
        if(isBrowser() && value !== null){
            let opts = new Array();
            let parsedOptions = "";
            
            if (options){
                for (const [key, value] of Object.entries(options)) {
                    opts.push(`${key}=${value}`);   
                };
                parsedOptions = opts?.join(";");
            }
            let _cookie = `${key}=` + encodeURIComponent(`${value}${parsedOptions!=="" ? ";"+ parsedOptions : ''}`)+";SameSite=strict"+";Secure";
            document.cookie= _cookie;

        } else {
            console.log("Error setting cookie: ", key);
        }     
    }

    public static get(key: string) {
        let cookie: string = "";
        if (isBrowser()) {
            cookie = document?.cookie?.split('; ')?.find(row => row.startsWith(`${key}=`))?.split('=')[1] ?? "";
        }
        return cookie != "" ? decodeURIComponent(cookie) : null;
    }
}

export default CookiesService;