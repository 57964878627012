// #region Imports
import React, { PropsWithChildren, ReactElement } from "react";
import { Item } from "./NavElements/NavItem";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile, { IMobileButtonData } from "./NavbarMobile";
// #endregion

// #region Interfaces
interface NavbarProps {
    navData: Item[];
    mobileButtonData: IMobileButtonData;
}
// #endregion

// #region Constants
export const navDesktopMinWidth = 1200;
// #endregion

const Navbar = (props: PropsWithChildren<NavbarProps>): ReactElement => {
    const {navData, mobileButtonData} = props
    // #region Component properties
    const isDesktop = useWindowWidth(navDesktopMinWidth);
    // #endregion

    // #region JSX
    return isDesktop ? (
        <NavbarDesktop {...{ navData }} />
    ) : (
        <NavbarMobile {...{ navData }} btnData={mobileButtonData}>
        {props.children}
        </NavbarMobile>
    );

    // #endregion
};

export default Navbar;
