// #region Imports
import React, { PropsWithChildren, ReactElement } from "react";
import NavItem from "../NavElements/NavItem";
import styles from "./NavbarDesktop.module.scss";
// #endregion

// #region Interfaces
interface NavbarDesktopProps
{
    navData: any;
}
// #endregion

const NavbarDesktop = ({ navData }: PropsWithChildren<NavbarDesktopProps>): ReactElement => (
    // #region JSX
    <nav className={styles.navbarDesktop}>
        <ul className={styles.navItemsWrapper}>
            {navData.map((item: object, i: number) => (
                <NavItem key={`${i} option`} {...{ item }}/>
            ))}
        </ul>
    </nav>
    // #endregion
);

export default NavbarDesktop;
