import React, { PropsWithChildren, useState, useEffect } from "react";
import { IMenuItem, IMenuNav, INavItem } from "./Interfaces/MenuItemsInterfaces";
import NavItem from "./NavItem";
import NavItemMobile from "./NavItemMobile";
import styles from "./MenuNav.module.scss";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { closeBtnSquare } from "../../../shared/icons"
import { chevron } from '../../../shared/icons';
import { useLocation } from '@reach/router';
import { navDesktopMinWidth } from "../Header/Navbar";


interface IMenuItemState {
    item: IMenuItem,
    open: boolean
}

const MemberMenu = (props: PropsWithChildren<IMenuNav>) => {
    const { items, currentItem, handleItemSelection } = props;

    let menuItemsStates: IMenuItemState[] = [];
    // -- Init every item in false
    for (const item of items) {
        menuItemsStates.push({ item, open: false });
    }
    // -- State
    const [menuItems, setMenuItems] = useState(menuItemsStates);

    const [open, setOpen] = useState(false);

    const [menuTitle, setMenuTitle] = useState<string>();

    const isDesktop = useWindowWidth(navDesktopMinWidth);

    const location = useLocation();

    useEffect(() => {
        let innerArray: IMenuItemState[] = [];
        for (const item of menuItems) {
            if (item.item.children?.length === 0) {
                let innerItem = item.item.slug === location.pathname;
                innerArray.push({ item: item.item, open: innerItem ? true : false });
            } else {
                let innerItem = item.item.children?.find(x => x.slug === location.pathname);
                innerArray.push({ item: item.item, open: innerItem ? true : false });
            }
        }
        setMenuItems(innerArray);
    }, []);

    useEffect(() => {
        if (open)
            document.body.classList.add("no-overflow");
        return () => document.body.classList.remove("no-overflow");
    }, [open]);


    useEffect(() => {
        let innerArray: IMenuItemState[] = [];
        for (const item of menuItems) {
            if (item.item.children?.length === 0) {
                let innerItem = item.item.slug === location.pathname;
                innerItem ? setMenuTitle(item.item.name) : null;
                innerArray.push({ item: item.item, open: innerItem });
            } else {
                let innerItem = item.item.children?.find(x => x.slug === location.pathname);
                innerItem ? setMenuTitle(item.item.name) : null;
                innerArray.push({ item: item.item, open: innerItem ? true : false });
            }
        }
        setMenuItems(innerArray);
    }, [location])

    const handleItemSelected = (menuItem: IMenuItem): void => {
        let innerArray: IMenuItemState[] = [];
        for (const item of menuItems) {
            if (item.item === menuItem) {
                if (menuItem.name != 'Dashboard') {
                    innerArray.push({ item: item.item, open: !item.open });
                } else {
                    innerArray.push({ item: item.item, open: true });
                }
            }
            else {
                innerArray.push({ item: item.item, open: false });
            }
        }
        setMenuItems(innerArray);
    }

    const closeMenu = () => {
        setOpen(!open);
    }



    return (
        <menu className={`${styles.menuNav} ${open && styles.open}`}>
            {isDesktop ? (
                <ul>
                    {
                        menuItems.map((navItem: IMenuItemState) => {
                            return (
                                navItem.item.children && <NavItem item={navItem.item} key={navItem.item.id} parent={navItem.item} childrens={navItem.item.children} handleItemSelection={handleItemSelected} isCurrent={navItem.open} />
                            )
                        })
                    }
                </ul>
            ) : (
                <ul className={styles.isMobile}>
                    <li className={`${styles.hasOptions} print-hidden ${styles.navItem}`}>
                        <button
                            className={`${open && styles.dashboardBtn}`}
                            onClick={() => setOpen(!open)}>
                            {menuTitle}
                            {open ? (<span className={styles.closeBtn}>{closeBtnSquare}</span>) : (<span className={styles.arrowBtn}>{chevron.down}</span>)}
                        </button>
                    </li>
                    {open &&
                        <ul>
                            {
                                menuItems.map((navItem: IMenuItemState) => {

                                    let currentSubMenu = false;

                                    if (navItem.item.children && navItem.item.children.find(x => x.slug === location.pathname)) {
                                        currentSubMenu = true;
                                    }
                                    return (
                                        navItem.item.children && <NavItem item={navItem.item} key={navItem.item.id} parent={navItem.item} childrens={navItem.item.children} handleItemSelection={handleItemSelected} isCurrent={navItem.open} handleClose={closeMenu} />
                                    )
                                })
                            }
                        </ul>
                    }
                </ul>
            )
            }
        </menu>
    );
};

export default MemberMenu;