import { useStaticQuery, graphql } from "gatsby";

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                allPrismicNavigation {
                    nodes {
                        dataRaw {
                            nav {
                                primary {
                                    nav_item_link {
                                        id
                                        type
                                    }
                                    nav_item_label {
                                        text
                                    }
                                }
                                items {
                                    nav_link {
                                        type
                                        slug
                                        uid
                                    }
                                    nav_link_label {
                                        text
                                        type
                                    }
                                }
                                slice_type
                            }
                        }
                    }
                }
            }
        `
    );

    return data.allPrismicNavigation.nodes[0].dataRaw.nav;
};
