// #region Imports
import React, { useState, ReactElement, PropsWithChildren } from "react";
import { Link } from "gatsby";
import { chevron } from "../../../../../shared/icons";
import NavDropdown from "./NavDropdown";
import routes from "../../../../../routes";
import { isBrowser } from "../../../../../shared/helpers";


// #endregion

// #region Interfaces
export interface Item {
    name: string;
    slug: string;
    items?: Item[];
    children?: Item[];
}

interface NavItemProps {
    item: Item;
    handleLinkClick?: () => void;
}
// #endregion

const isIOS = isBrowser() ? /iPhone|iPad|iPod/.test( navigator.userAgent ) : false

const NavItem = (props: PropsWithChildren<NavItemProps>): ReactElement => {
    // #region Component properties
    const [optionsVisible, setOptionsVisibility] = useState(false);
    // #endregion

    const hasChildren = props.item.slice_type === "nav_item_multiple";

    const pointerEventName = isIOS ?  "onMouseDown" : "onPointerDown"
  
    // #region JSX
    return (
        <li className={`navItem ${hasChildren ? "hasOptions" : ""}`}>
            {hasChildren ? (
                <div>
                    <button
                        className={optionsVisible ? "optionsVisible" : ""}
                        onClick={() => {setOptionsVisibility(prevState => !prevState)}}
                    >
                        {props.item.primary.nav_item_label[0].text}
                        {<span>{chevron.down}</span>}
                    </button>
                </div>
            ) : (
                <div>
                    <Link
                        to={`${routes[props.item.primary.nav_item_link.type]}`}
                        {...{[pointerEventName]: props.handleLinkClick}}
                    >
                        {props.item.primary.nav_item_label[0].text}
                    </Link>
                </div>
            )}

            {hasChildren && optionsVisible && (
                <NavDropdown
                    items={props.item.items}
                    closeDropdown={() => setOptionsVisibility(false)}
                    handleLinkClick={props.handleLinkClick}
                />
            )}
        </li>
    );
    // #endregion
};

export default NavItem;
