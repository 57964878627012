export const navMenuItems = [
    {
        id: 1,
        name: "Dashboard",
        slug: "/members/dashboard",
        children: []
    },
    {
        id: 2,
        name: "Account",
        children: [
            {
                id: 1,
                name: "Member Information",
                slug: "/members/info",
            },
            {
                id: 2,
                name: "Reset Password",
                slug: "/members/reset-password",
            }
        ]
    },
    {
        id: 3,
        name: "My Workouts",
        children: [
            {
                id: 1,
                name: "Check-Ins",
                slug: "/members/check-ins",
            },
            {
                id: 2,
                name: "Upcoming Classes",
                slug: "/members/upcoming-classes",
            },
            {
                id: 3,
                name: "Past Classes",
                slug: "/members/past-classes",
            },
        ]
    },
    {
        id: 4,
        name: "Membership & Billing",
        children: [
            {
                id: 1,
                name: "Membership",
                slug: "/members/membership",
            },
            {
                id: 2,
                name: "Payment Method",
                slug: "/members/payment",
            },
            {
                id: 3,
                name: "Invoices",
                slug: "/members/invoices",
            },
            {
                id: 4,
                name: "Freeze Membership",
                slug: "/members/freeze",
            },
            {
                id: 5,
                name: "Cancel Membership",
                slug: "/members/cancel",
            },
        ]
    }
];