import { useRef, useState, useLayoutEffect } from "react";
import { isBrowser } from "../shared/helpers";
/**
 * Checks if the window matches any given width
 *
 * @param {number} width
 */

const useWindowWidth = (width: number): boolean =>
{
    const mediaQuery = useRef(isBrowser() ? window.matchMedia(`(min-width: ${width}px)`) : null as any);
    const [match, setMatch] = useState<boolean>(mediaQuery.current?.matches);
    const handleMatchToggle = ({ matches }: any): void => setMatch(matches);

    useLayoutEffect(() =>
    {
        if (!isBrowser()) return;

        const ref = mediaQuery.current;
        const supportsEventListener = ref && ref.addEventListener;

        handleMatchToggle(mediaQuery.current);

        if (supportsEventListener as () => boolean)
            ref.addEventListener("change", handleMatchToggle);
        else
            ref.addListener(handleMatchToggle);

        return () =>
        {
            if (supportsEventListener as () => boolean)
                ref.removeEventListener("change", handleMatchToggle);
            else
                ref.removeListener(handleMatchToggle);
        };
    }, []);

    return match;
};
export default useWindowWidth;
// #region Constants
export const heroDesktopMinWidth = 768;
// #endregion

