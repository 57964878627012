// #region Imports
import { Link, navigate, graphql, useStaticQuery } from "gatsby";
import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import useHasWindow from "../../../../../hooks/useHasWindow";
import routes from "../../../../../routes";
import { closeMenuIcon, menuIcon } from "../../../../../shared/icons";
import LoggedMenu from "../LoggedMenu";
import NavItem, { Item } from "../NavElements/NavItem";
import styles from "./NavbarMobile.module.scss";
import { socialMediaIcons } from "../../../../../shared/icons";
// #endregion

interface INavButton {
    text:
    {
        text: string;
        type: string;
        span: any[]
    }[];
    link: string;
}
export interface IMobileButtonData {
    tryUs: INavButton;
    contactUs: INavButton;
}

// #region Interfaces
interface NavbarMobileProps {
    navData: Item[];
    btnData: IMobileButtonData;
}
// #endregion

const NavbarMobile = (props: PropsWithChildren<NavbarMobileProps>): ReactElement => {
    const { navData, btnData } = props
    // #region Component properties
    const [menuVisible, setMenuVisibility] = useState(false);
    const hasWindow = useHasWindow();
    // #endregion

    useEffect(() => {
        menuVisible ? document.body.style.setProperty("overflowY", "hidden") : document.body.style.removeProperty("overflowY");
    }, [menuVisible]);
    // #endregion

    const logOut = (e: any) => {
        e.preventDefault()
        localStorage.removeItem('jwt-token');
        navigate("/members/login");
        setMenuVisibility(!menuVisible)
    }

    const query = useStaticQuery(
        graphql`
        query {
            allPrismicMainLayout {
                edges {
                    node {
                        dataString
                    }
                }
            }
        }
        `
    );

    const data = JSON.parse(query.allPrismicMainLayout.edges[0].node.dataString);

    return (
        // #region JSX
        <div className={styles.navbarMobile}>
            <button
                className={`${styles.navToggler} ${menuVisible ? styles.toggled : ""}`}
                onClick={() => setMenuVisibility(!menuVisible)}
            >
                {menuVisible ? closeMenuIcon : menuIcon}
            </button>

            <>
                <ul className={`${styles.navItemsWrapper} ${menuVisible ? styles.listVisible : ""}`} >
                    {hasWindow && localStorage.getItem('jwt-token') &&
                        <LoggedMenu handleClose={() => setMenuVisibility(!menuVisible)} />
                    }
                    {hasWindow && !localStorage.getItem('jwt-token') &&
                        <li className={styles.btnWrapper}>
                            <div className={styles.tryAndJoin}>
                                {btnData.tryUs.text && btnData.tryUs.link && (
                                    <Link
                                        to={`${routes[btnData.tryUs.link?.type]}`}
                                        className={styles.btnHeaderMobile}
                                        state={{ club: null }}
                                        onClick={() => setMenuVisibility(!menuVisible)}
                                    >
                                        {btnData.tryUs.text[0]?.text}
                                    </Link>
                                )}

                                {btnData.contactUs.text && btnData.contactUs.link && (
                                    <Link
                                        to={`${routes[btnData.contactUs.link?.type]}`}
                                        className={styles.btnHeaderMobile}
                                        state={{ club: null }}
                                        onClick={() => setMenuVisibility(!menuVisible)}
                                    >
                                        {btnData.contactUs.text[0]?.text}
                                    </Link>
                                )}
                            </div>
                            <Link
                                className={`${styles.btnHeaderMobile}`}
                                to={'/members/login'}
                                onClick={() => setMenuVisibility(!menuVisible)}
                            >
                                Log In / Register
                            </Link>
                        </li>}
                    {navData.map((item: Item, i: number) => (
                        <NavItem
                            key={`${i}-option`}
                            handleLinkClick={() => setMenuVisibility(!menuVisible)}
                            {...{ item }}
                        />
                    ))}

                    {props.children}
                    <li>
                        <div>
                            {hasWindow && localStorage.getItem('jwt-token') && <button onClick={logOut} className={styles.logOut}>Log out</button>}
                        </div>
                    </li>
                </ul>
            </>
        </div>
        // #endregion
    );
};

export default NavbarMobile;
