import { useRef, useEffect } from "react";
import { isBrowser } from "../shared/helpers";

/**
 * Sets and removes event listeners
 *
 * @param event - A string which represents the event to listen
 * @param callback - The callback function for the event listener
 * @param target - Target element (window by default)
 * @param passive - Determines whether the listener is passive or not
 */
export const useEventListener = (
    event: string,
    callback: Function,
    target: any = isBrowser() ? window : null,
    passive: boolean = true
) =>
{
    const savedCallback = useRef<Function>(null as any);

    /*
        Updates ref's value if callback changes allowing the effect below to always get the latest callback.
        This avoids having to pass it in the effect deps preventing it from running on every render.
     */
    useEffect((): void =>
    {
        savedCallback.current = callback;
    }, [callback]);

    useEffect((): (() => void) =>
    {
        if (!isBrowser() || !(target && target.addEventListener)) return null as any;

        const eventListener = (event: Event) => savedCallback?.current && savedCallback.current(event);
        target.addEventListener(event, eventListener, { passive });

        return () => target.removeEventListener(event, eventListener);
    }, [event, target, passive]);
};
