import { Link, navigate } from 'gatsby';
import React, { PropsWithChildren, ReactElement, useRef, useState } from 'react'
import { isWindow } from '../../../../../shared/helpers';
import { navMenuItems } from '../../../MemberMenu/NavMenu';
import styles from './LoggedMenu.module.scss';
import { chevron } from "../../../../../shared/icons";
import useWindowWidth from '../../../../../hooks/useWindowWidth';
import { navDesktopMinWidth } from '..';
import { useEventListener } from '../../../../../hooks/UseEventListener';

interface ILoggedProps {
    handleClose?: () => void
}

interface IMenuLink {
    id: string,
    name: string,
    slug: string,
}
const LoggedMenu = (props: PropsWithChildren<ILoggedProps>): ReactElement => {
    // const[links, setLinks] = useState<IMenuLink[]>([]);

    let links: IMenuLink[] = [];
    const dropdown = useRef(null as any);

    for (const item of navMenuItems) {
        if (!item.children || item.children.length === 0) {
            links.push({ id: `${item.id}`, name: item.name, slug: item.slug as string });
        }
        else {
            const child = item.children[0];
            links.push({ id: `${item.id}.${child.id}`, name: child.name, slug: child.slug as string });
        }
    }

    // setLinks(l);

    const [dropdownVisible, setDropdownVisible] = useState(false);
    let profileImage = '/assets/image/blank-user.png';
    const isDesktop = useWindowWidth(navDesktopMinWidth);

    const handleClick = () => {
        
    if(isDesktop){
        setDropdownVisible(!dropdownVisible);
    }
    else {
        navigate('/members/dashboard')
        props.handleClose ? props.handleClose() : null ;
    }

    }

    const logOut = (e: any) => {
        e.preventDefault()
        localStorage.removeItem('jwt-token');
        isWindow && window.location.reload();
    }

    const handleClickOutside = (e: PointerEvent): void => {
        if (
            dropdown?.current &&
            !dropdown.current.contains(e.target as HTMLElement)
        )
        setDropdownVisible(false)
    };
    
    if (isWindow && localStorage.getItem('image'))
        profileImage = `data:image/jpeg;base64,${localStorage.getItem('image')}`;

    useEventListener("pointerdown", handleClickOutside);
    
    return (
        <div className={styles.menuWrapper} ref={dropdown}>
            <div className={`${styles.btnLogged} ${dropdownVisible? styles.dropdownVisible : ""}`} onClick={handleClick}>
                <div className={styles.imageContainer}>
                    <img
                        src={profileImage}
                        alt={`profile-photo`}
                        className={styles.image}
                    />
                </div>
                {isWindow && localStorage.getItem('name') && <p className={styles.name}>{localStorage.getItem('name')}</p>}
                <span className={styles.arrow}>{chevron.down}</span>
            </div>

            {dropdownVisible &&
                <div className={styles.dropdownMenu}>
                    <ul>
                        {
                            links.map(x =>
                                <li key={x.id} className={styles.links} onClick={()=>setDropdownVisible(false)}><Link to={x.slug} className={styles.link}>{x.name}</Link></li>
                            )
                        }
                        <li className={styles.links}>
                            <button onClick={logOut} className={styles.logOut}>Log out</button>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

export default LoggedMenu
