export const headerConfig = {
    buttonsExcludedPath: {
        corporateEnrollment: "/corporate-enrollment/",
    },
};

export const layoutConfig = {
    membersLayoutExcludePath: {
        create: "/members/create",
        restore: "/members/restore-password",
        createPassword: "/members/create-password",
        default: "/members/login"
    },
    membersLayoutIncludePath: {
        dashboard: "/members/dashboard",
        info: "/members/info",
        resetPassword: "/members/reset-password",
        checkIn: "/members/check-ins",
        upcomingClasses: "/members/upcoming-classes",
        pastClasses: "/members/past-classes",
        membership: "/members/membership",
        payment: "/members/payment",
        invoices: "/members/invoices",
        freezeMembership: "/members/freeze",
        cancelMembership: "/members/cancel",
        paymentMethod: "/members/payment-method"
    },
};
